import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaShoppingCart, FaBed, FaPenNib } from 'react-icons/fa';
import { IoLogIn, IoPersonAdd } from 'react-icons/io5';
import { FaLeaf } from 'react-icons/fa';
import './Global.css';

function Header() {
  return (
    <header className="fixed top-0 left-0 w-full bg-white text-gray-700 z-40 flex items-center justify-between p-3 shadow-xl">
      
   
      
        
      <div className="flex space-x-2 ">
      <div className="bg-white rounded-3xl  flex items-center  border-1 ">
      <FaLeaf className="text-gray-700 text- mr-1" /> {/* Icon on the left */}
      <h1 className="text-gray-700 font-bold text-2xl">Waiiona</h1>
    </div>
       
   <Link to="/Home" className="flex flex-col items-center">
     <FaHome className="text-xl " />
     <span className="text-xs">Home</span>
   </Link>
   <Link to="/MarketPage" className="flex flex-col items-center">
     <FaShoppingCart className="text-xl  " />
     <span className="text-xs">Market</span>
   </Link>
   <Link to="/Hostel" className="flex flex-col items-center ">
     <FaBed className="text-xl  " />
     <span className="text-xs">Rent</span>
   </Link>
   <Link to="/blogpage" className="flex flex-col items-center">
     <FaPenNib className="text-xl " />
     <span className="text-xs">Blog</span>
   </Link>
  
 </div>

    
     
   


      <div className="flex space-x-1 text-center">
      
        <Link to="/Login" className="flex flex-col items-center">
          <IoLogIn className="text-xl" />
          <span className="text-xs">Login</span>
        </Link>
        <Link to="/SignUp" className="flex flex-col items-center">
          <IoPersonAdd className="text-xl " />
          <span className="text-xs">SignUp</span>
        </Link>
      </div>
    </header>
  );
}

export default Header;
