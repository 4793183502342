import React, { useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoPersonSharp } from 'react-icons/io5';
import { FaHouse, FaSellsy, FaStore, FaBlog } from 'react-icons/fa6';
import { BsChatDotsFill } from 'react-icons/bs';
import { HiShoppingCart } from 'react-icons/hi';
import { UserContext } from './UserContext';

const InsideHeader = ({ handleLogout }) => {
    const { user } = useContext(UserContext); // Get the user from UserContext
    const location = useLocation();
    const [cartNotificationCount, setCartNotificationCount] = useState(0);

    // Determine the active path
    const currentPath = location.pathname;

    const navItems = [
        { id: 'profile', label: 'Profile', path: '/welcome', icon: <IoPersonSharp size={22} /> },
        { id: 'hostels', label: 'Hostels', path: '/Accomodation', icon: <FaHouse size={22} /> },
        { id: 'mystore', label: 'MyStore', path: '/product', icon: <FaSellsy size={22} /> },
        { id: 'market', label: 'Market', path: '/market', icon: <FaStore size={22} /> },
        { id: 'cart', label: 'Cart', path: '/cart', icon: <HiShoppingCart size={22} /> },
        { id: 'chat', label: 'Chat', path: '/chat', icon: <BsChatDotsFill size={22} /> },
        { id: 'blog', label: 'Blog', path: '/blog', icon: <FaBlog size={22} /> },
    ];

    return (
        <header className="fixed bottom-0 left-0 w-full bg-white text-gray-500 z-40 flex items-center justify-between p-1 border-solid border-t-2 border-gray-200 shadow-3xl">
            <div className='flex flex-row flex-grow items-center justify-center'>
                {navItems.map(item => (
                    <Link key={item.id} to={item.path} className={`relative flex flex-col mr-3 items-center text-xs md:ml-4 bg-transparent border-none ${currentPath === item.path ? 'text-black' : ''}`}>
                        <button className="flex flex-col items-center bg-transparent border-none">
                            {item.icon}
                            <span >{item.label}</span>
                        </button>
                        
                    </Link>
                ))}
            </div>
        </header>
    );
};

export default InsideHeader;
