// src/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Welcome from './Welcome';
import { Link } from 'react-router-dom';
import { BsFillPersonFill, BsFillLockFill } from 'react-icons/bs';
import { MdEmail } from "react-icons/md";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; 

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);




    
    const showNotice = (message) => {
        setMessage(message);
        
        // Set a timeout to clear the notice after 3 seconds
        setTimeout(() => {
          setMessage('');
        }, 5000); // 3000 milliseconds = 3 seconds
      };




  
  
    
      const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };



    const handleLogin = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);

        try {
            const response = await axios.post('https://mybackend-new.onrender.com/login', {
                username,
                password
            });

            if (response.status === 200) {
                localStorage.setItem('token', response.data.token);
                setLoggedIn(true); // Update loggedIn state to true upon successful login
            } else {
                setMessage('Login failed. You entered wrong credentials');
            }
        } catch (error) {
            showNotice('Login failed. You entered wrong credentials.');
        }
        finally {
            setIsSubmitting(false); // Reset the loading state
          }
    };

    if (loggedIn) {
        return <Welcome />;    
    }

    return (
        <div className="relative min-h-screen">
          

            <div className="relative z-10">
                <Header />
                <div className="pt-20 flex  items-center justify-center bg-opacity-0 bg-gray-100">
                    <div className="max-w-md w-full p-4 bg-daisy-200 rounded-3xl shadow-2xl m-3  border-solid border-2 border-gray-600">
                        <h2 className="text-2xl font-bold mb-2 text-center">Welcome to Waiiona</h2>
                        <h5 className="mb-4 text-center">Sign in to start your session</h5>

                        <form onSubmit={handleLogin}>
                            <div className="mb-4 relative">
                                <input
                                    type="text"
                                    className="w-full px-3 py-2 pr-10 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                    placeholder="Enter your email"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                                <MdEmail className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
                            </div>
                            <div className="mb-4 relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    className="w-full px-3 py-2 pr-10 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            
                                <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
        >
          {showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
        </button>
                            </div>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`w-full bg-gray-700 text-white py-2 px-4 rounded-xl focus:outline-none hover:bg-gray-500 ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-700'}`}
                            >
                                  {isSubmitting 
                                   ? 'logging in...' : 'Login'
                                   }
                            </button>
                        </form>
                        {message && <p className="text-red-500 text-center mt-4">{message}</p>}
                        <div className="mt-4 text-center">
                            <p className="text-sm">
                                Forgot password? <Link to="/Reset" className="text-blue-500">Reset</Link>
                            </p>
                            <p className="text-sm">
                                Don't have an account? <Link to="/SignUp" className="text-blue-500">Sign up</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
