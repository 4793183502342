import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import { useState } from 'react';
import Login from './Login';
import Welcome from './Welcome';
import SignUp from './SignUp';
import Market from './Market';
import MyProducts from './MyProducts';
import ProductForm from './ProductForm';
import MarketPage from './MarketPage';
import Home from './Home';
import Blog from './Blog';
import Blogpage from './Blogpage';
import ResetPassword from './ResetPassword';
import ResetPasswordRequest from './ResetPasswordRequest';
import Accomodation from './Accomodation';
import PostHouse from './PostHouse';
import RentHouse from './RentHouse';
import PostHostelOut from './RentHostel';
import { UserProvider } from './UserContext';
import Cart from './Cart';
import Notification from './Notification';
import Chat from './Chat';
import PrivateRoute from './PrivateRoute';

import RentHostel from './RentHostel';
import About from './About';
import Admin from './Admin';


function App() {





  return (
    <UserProvider>
      <Router>
   
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="/Hostel" element={<RentHostel/>} />
          <Route path="/Reset" element={<ResetPasswordRequest />} />
          
          <Route  path="/ResetPassword" element={<ResetPassword />}/>
           
          <Route path="/Admin" element={<Admin />} />
   
  
          <Route
            path="/Welcome"
            element={
              <PrivateRoute>
                <Welcome />
              </PrivateRoute>
            }
          />

          <Route
            path="/Market"
            element={
              <PrivateRoute>
                <Market />
              </PrivateRoute>
            }
          />

          <Route
            path="/product"
            element={
              <PrivateRoute>
                <MyProducts />
              </PrivateRoute>
            }
          />

          <Route
            path="/Add"
            element={
              <PrivateRoute>
                <ProductForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/MarketPage"
            element={
            
                <MarketPage />
              
            }
          />
          <Route
            path="/blog"
            element={
              <PrivateRoute>
                <Blog />
              </PrivateRoute>
            }
          />
          <Route
            path="/blogpage"
            element={
             
                <Blogpage />
              
            }
          />




          <Route
            path="/Accomodation"
            element={
              <PrivateRoute>
                <Accomodation />
              </PrivateRoute>
            }
          />

    <Route
            path="/about"
            element={
              <PrivateRoute>
                <About />
              </PrivateRoute>
            }
          />


          <Route
            path="/PostHouse"
            element={
              <PrivateRoute>
                <PostHouse />
              </PrivateRoute>
            }
          />


          <Route
            path="/RentHouse"
            element={
              <PrivateRoute>
                <RentHouse />
              </PrivateRoute>
            }
          />
          <Route
            path="/Hostel"
            element={
              <PrivateRoute>
                <PostHostelOut />
              </PrivateRoute>
            }
          />
          <Route
            path="/Cart"
            element={
              <PrivateRoute>
                <Cart />
              </PrivateRoute>
            }
          />
          <Route
            path="/Notification"
            element={
              <PrivateRoute>
                <Notification />
              </PrivateRoute>
            }
          />
          <Route
            path="/Chat"
            element={
              <PrivateRoute>
                <Chat />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
