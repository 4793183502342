import React, { useState, useEffect} from 'react';
import axios from 'axios';




import InsideHeader from './InsideHeader';


import { useContext} from 'react';
import { UserContext } from './UserContext';
import {FaStore} from 'react-icons/fa';
import AnotherHeader from './AnotherHeader';
import { IoMdPerson } from "react-icons/io";
import { FaHouseUser } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import {IoMdCall } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { ImProfile } from "react-icons/im";


const Market = ()  => {

  const { user } = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [currentProductOwner, setCurrentProductOwner] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);



 
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const res = await axios.get(`https://mybackend-new.onrender.com/products?search=${searchTerm}`);
      

      setProducts(res.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


    
  const handleMessageClick = (ownerId) => {
    const isConfirmed = window.confirm('Would you like to be redirected to WhatsApp chat?');

    if(isConfirmed){    
    setCurrentProductOwner(ownerId);
    setShowMessageModal(true);
    };

  };

  const handleSendMessage = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`https://mybackend-new.onrender.com/messages`, {
        ownerId: currentProductOwner,
        content: messageContent
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setShowMessageModal(false);
      setMessageContent('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };


  const toggleDescription = (productId) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };

  const getTimeDifference = (timestamp) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const differenceInSeconds = Math.floor((now - createdAt) / 1000);

    const days = Math.floor(differenceInSeconds / (3600 * 24));
    const hours = Math.floor((differenceInSeconds % (3600 * 24)) / 3600);
    const min = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
    } else if (min > 0) {
      return `${min} min${min !== 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.owner_username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };


  


  const showNotice = (message) => {

      alert(message);
    // Set a timeout to clear the notice after 3 seconds
    setTimeout(() => {
      return;
    }, 3000); // 3 seconds
  };



  const handleOrderClick = async (productId, owner, email) => {
    if (user) {
      const buyerId = user.id;

      console.log(email);
  
      // Check if product is already in the cart
      try {
        const token = localStorage.getItem('token');
        const checkCartResponse = await axios.get('https://mybackend-new.onrender.com/check-cart', {
          params: { product_id: productId, buyer_id: buyerId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (checkCartResponse.data.exists) {
          showNotice('Product is already in your cart. Cannot place order again.');
          return;
        }
  
        const isConfirmed = window.confirm('Are you sure you want to order the product?');
        if (isConfirmed) {
          if (buyerId !== owner) {
            try {
              const orderResponse = await axios.post(
                'https://mybackend-new.onrender.com/orders',
                {
                  productId,
                  Owner: owner,
                  buyerId,
                  email
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
  
              if (orderResponse.status === 200) {
                alert('Order placed successfully! Check your cart');
              }
            } catch (error) {
              console.error('Error placing order:', error);
            }
          } else {
            showNotice('Oops! Sorry, you cannot order your own Product');
          }
        }
      } catch (error) {
        console.error('Error checking cart:', error);
      }
    }
  };
  

  
  const toggleImage = (image) => {
    console.log("Image clicked");
    setSelectedImage(selectedImage === image ? null : image);

  };




  const togglePersonModal = (product) => {
    setCurrentProduct(product);
    setShowPersonModal(!showPersonModal);
  };


  return (
    <div  >
      
      <div  className="relative min-h-screen flex">
        <AnotherHeader/>
        <InsideHeader  />
        <div className="flex-1 p-2 flex">
          

          {loading ? (<div className='mt-20' >....loading</div>
            ):

             (
          <div className="flex-1 p-0 rounded-6xl pt-20">
            <div className=" mx-auto  bg-white">
            <div className="flex items-center justify-center mb-4">
             <FaStore className="text-2xl mr-2" />
              <h2 className="text-2xl font-bold text-center">Market</h2>
               </div>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Search by name, description, or owner"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="p-2 border w-full border-gray-300 rounded-md"
                />
              </div>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-1  ">
                {filteredProducts.map(product => (
                  <div key={product.id} className="border rounded-md p-1">
                        {product.picture && (
                    <img src={`${product.picture}`} alt={product.name}   
                    
                    className="w-full h-44 object-cover mb-2 rounded-md border" 
                    onClick={() => togglePersonModal(product)}
                
                    />
                     )}


                <div className='pl-1 pr-1' >
                    <h2 className="text-md font-bold ">{product.name}</h2>
                    <div className="text-gray-700 text-sm  max-h-70 overflow-hidden ">
                      {expandedDescriptions[product.id] || product.description.length <= 20
                        ? product.description
                        : `${product.description.substring(0, 8)}...`}
                      {product.description.length > 5 && (
                        <span
                        onClick={() => togglePersonModal(product)}
                          className="text-blue-500 text-xs cursor-pointer"
                        >
                          Read More
                        </span>
                      )}
                    </div>
                    <p className="text-sm font-semibold">Price: MK{product.price}</p>
                    <p className='text-xs'>Status: {product.status}</p>
                    <p className='text-xs'>Seller: {product.owner_username}</p>
                    <div className="flex items-center mt-2">
                      

                
                    <button
                        onClick={() => handleOrderClick(product.id,product.owner_id,product.email)}
                         
                        className="text-gray-800 text-xs font-semibold mr p-0 border-none bg-gray-300 rounded-lg cursor-pointer"
                      >
                       
                       <h6 className='p-1' >Shop Now</h6>
                     
                      </button>
                   
            
                    </div>
                    <p className="text-xs mt-2 pb-2">Posted {getTimeDifference(product.created_at)}</p>
                  </div>
    
                 </div>

                ))}

     
              </div>
            </div>
          
          </div>
             )}
        </div>
        
        {showMessageModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded shadow-lg">
              <h2 className="text-xl mb-4">Send a message to the owner</h2>
              <textarea
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mb-4"
                rows="4"
                placeholder="Type your message here..."
              ></textarea>
              <div className="flex justify-end">
                <button
                  onClick={() => setShowMessageModal(false)}
                  className="bg-gray-300 text-black p-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSendMessage}
                  className="bg-blue-500 text-white p-2 rounded"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {showPersonModal && currentProduct && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className=" border bg-white border-gray-300  w-full max-w-lg h-full overflow-auto">

      {/* Full Picture at the Top */}
      <div className='w-full'>
        {currentProduct.picture && (
          <img
            src={`${currentProduct.picture}`}
            alt={currentProduct.name}
            onClick={() => toggleImage(`${currentProduct.picture}`)}
            className="w-full h-auto object-cover rounded-lg border-2 border-gray"
          />
        )}
      </div>

      {/* Other Content Below the Picture */}
      <div className="p-4">
      <p className='text-lg font-bold'> {currentProduct.name}</p>
        <p className='text-2xs  mb-2 text-gray-700 text-sm mb-2  max-h-24 overflow-hidden'> {currentProduct.description}</p>
        <p className='text-2xs font-bold'>Price: MK{currentProduct.price}</p>
        <p className='text-2xs'>Status: {currentProduct.status}</p>


        <div className="flex items-center justify-center mt-4">
          <ImProfile size={20} className="mr-1" />
          <h2 className="text-md font-bold text-center">Our Business Profile</h2>
        </div>

        <hr className="border-t-2 border-gray-600 mb-4 mt-2" />
        
        <p className='mb-2 text-gray-700 text-center text-sm mb-2  mt-2 max-h-24 '> {currentProduct.userDescription}</p>
        <p className='text-2xs'>Seller: {currentProduct.owner_username}</p>
        <p className='text-2xs'>Location: {currentProduct.location}</p>

        <div className="flex space-x-4 mt-2">
          <button
            onClick={togglePersonModal}
            className="p-2 bg-red-800 text-white rounded-xl flex items-center"
          >
            <MdCancel className="mr-1" />
            Close
          </button>
          <button
                        onClick={() => handleOrderClick(currentProduct.id,currentProduct.owner_id)}
                      
                        className="text-gray-800 text-xs font-semibold mr p-0 border-none bg-gray-300 rounded-lg cursor-pointer"
                      >
                       <h6 className='p-2' >Shop Now</h6>
                     
                      </button>
                   
        </div>
      </div>
    </div>
  </div>
)}

{selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Product"
              className="max-w-full max-h-full rounded-xl"
              onClick={() => setSelectedImage(null)}
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-0 right-0 p-2 text-white bg-black rounded-full"
            >
              &times;
            </button>
          </div>
        </div>
      )}

      <br/><br/>
    </div>
  );
}

export default Market;
