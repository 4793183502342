import React from 'react';
import Header from './Header';
import Background from './Background';
import { GiReceiveMoney } from "react-icons/gi";
import { FaShoppingCart, FaBed, FaHeadset } from "react-icons/fa";
import './Home.css'; // Import the CSS file
import { Link
  
 } from 'react-router-dom';
function Home() {
  return (
    <div>
      <Header />
      <div className="relative min-h-screen">
        <Background />
        <div className="relative z-10 min-h-screen flex flex-col justify-center  items-center pt-20 py-8 px-4">
          <div className="container mx-auto text-center mb-4">
            <h1 className="header text-2xl font-bold  text-gray-700 mb-2 ">
              Waiiona Online Market
            </h1>
            <p className="text-center text-lg  mb-4">
              Discover the best products and enjoy the best services we offer. We are committed to providing top-notch customer satisfaction and quality products.
            </p>

            <hr className="border-t-2 border-black mb-2 mt-2" />
          </div>
                      
           <Link to = '/SignUp'>
          <div className="bg-gray-600 p-3 mb-4 rounded-xl shadow-lg flex items-center ">
            
              <h2 className="text-xl text-white font-bold">Get Started</h2>
              
            </div>
            </Link>
           
           

          <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <Link to = "/MarketPage">
            <div className="bg-white p-6 rounded-2xl shadow-lg flex flex-col items-center bg-opacity-90">
              <FaShoppingCart size={40} className="text-blue-500 mb-4" />
              <h2 className="text-xl font-bold mb-2">Wide Range of Products</h2>
              <p className="text-center">High-quality products available at your fingertips.</p>
            </div>

            </Link>

            <Link to = "/Hostel">

            <div className="bg-white p-6 rounded-2xl shadow-lg flex flex-col items-center bg-opacity-90">
              <FaBed size={40} className="text-green-500 mb-4" />
              <h2 className="text-xl font-bold mb-2">Accommodation Rental</h2>
              <p className="text-center">Find the best places to stay with ease.</p>
            </div>
            </Link>

            <div className="bg-white p-6 rounded-2xl shadow-lg flex flex-col items-center bg-opacity-90">
              <GiReceiveMoney size={40} className="text-red-500 mb-4" />
              <h2 className="text-xl font-bold mb-2">Digital Marketing</h2>
              <p className="text-center">Boost your online presence with our marketing services.</p>
            </div>

            <div className="bg-white p-6 rounded-2xl shadow-lg flex flex-col items-center bg-opacity-90">
              <FaHeadset size={40} className="text-purple-500 mb-4" />
              <h2 className="text-xl font-bold mb-2">24/7 Customer Support</h2>
              <p className="text-center">We're here to help, anytime you need us.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
