import React, { useState, useEffect } from 'react';
import axios from 'axios';

import InsideHeader from './InsideHeader';
import { BsChatDotsFill } from "react-icons/bs";
import AnotherHeader from './AnotherHeader';

const Notification = () => {
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [currentRecipientId, setCurrentRecipientId] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [notice, setNotice] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://mybackend-new.onrender.com/messages', {
        headers: { Authorization: token }
      });
      setMessages(response.data);
      setLoadingMessages(false);
    } catch (err) {
      console.error('Error fetching messages:', err);
      setLoadingMessages(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://mybackend-new.onrender.com/users', {
        headers: { Authorization: token }
      });
      setUsers(response.data);
    } catch (err) {
      console.error('Error fetching users:', err);
    }
  };

  useEffect(() => {
    fetchMessages();
    fetchUsers();
    const interval = setInterval(() => {
      fetchMessages();
      fetchUsers();
    }, 1000); // Refresh every second

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  const handleSendMessage = async () => {
         showNotice('Message sent');
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        'https://mybackend-new.onrender.com/messages',
        {
          recipientId: currentRecipientId,
          description: messageContent
        },
        {
          headers: { Authorization: token }
        }

       
      );

      setMessageContent('');
      // Clear notice after 3 seconds
      setTimeout(() => setNotice(''), 3000);

      // Optionally, update messages here if needed
      // Example:
      // setMessages(prevMessages => {
      //   // Update messages state
      // });
      
      setShowMessageModal(false);
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  const handleUserSearch = (query) => {
    setSearchTerm(query);
    if (query.trim() === '') {
      setSearchResults([]);
      return;
    }
    const filteredUsers = users.filter(user =>
      user.username.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredUsers);
  };

  const handleUserSelect = (userId) => {
    setCurrentRecipientId(userId);
    setSearchTerm(''); // Clear search term
    setSearchResults([]); // Clear search results
    setShowMessageModal(true);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const showNotice = (message) => {
    setNotice(message);
    
    // Set a timeout to clear the notice after 3 seconds
    setTimeout(() => {
      setNotice('');
    }, 5000); // 3000 milliseconds = 3 seconds
  };





  const handleDeleteMessage = async (messageId) => {
    console.log(messageId);
    try {
      await axios.delete(`https://mybackend-new.onrender.com/messages/${messageId}`);
  
      // Update the messages state to remove the deleted message
      setMessages(prevMessages => 
        prevMessages.map(senderChat => ({
          ...senderChat,
          messages: senderChat.messages.filter(message => message.id !== messageId)
        })).filter(senderChat => senderChat.messages.length > 0)
      );
    } catch (err) {
      console.error('Error deleting message:', err);
    }
  };
  



  return (
    <div className="flex flex-col min-h-screen">
      <AnotherHeader/>
      <InsideHeader toggleSidebar={toggleSidebar} />
      <div className="flex flex-1">
      
        <main className="flex-grow p-4 pt-20">
        <div className="flex items-center justify-center mb-4">
          <BsChatDotsFill className="text-2xl mr-2" />
         <h2 className="text-2xl font-bold text-center">My Chat</h2>
         </div>
          <div className="mb-4">
            <div className="flex items-center ">
              <input
                type="text"
                placeholder="Search users by their username"
                value={searchTerm}
                onChange={(e) => handleUserSearch(e.target.value)}
                className="p-2 border w-full border-gray-300 rounded-xl"
              />
            </div>
            {searchResults.length > 0 && (
              <div className="mt-2">
                <ul className="border border-gray-300 rounded">
                  {searchResults.map(user => (
                    <li
                      key={user.id}
                      className="p-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleUserSelect(user.id)}
                    >
                      {user.username} - {user.email}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="mt-8">
            {loadingMessages ? (
              <p>Loading messages...</p>
            ) : messages.length === 0 ? (
              <p>No messages available</p>
            ) : (
              <div>
                    {messages.map(senderChat => (
                  <div key={senderChat.senderId} className="mb-8">
                    <h3 className="text-xl font-bold">FROM: {senderChat.senderUsername}</h3>
                    <ul className="space-y-4">
                      {senderChat.messages.map(message => (
                        <li key={message.id} className="p-2 border  border-gray-300 rounded-xl">
                          <h3 className="text-3xs font-semi-bold">{message.description}</h3>
                          <p className='font-bold text-sm' >Received at: {new Date(message.created_at).toLocaleString()}</p>
                          <button
                            onClick={() => handleDeleteMessage(message.id)}
                            className="bg-red-800 text-white px-2 py-1 rounded-md mt-2"
                          >
                            Delete
                          </button>
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={() => {
                        setCurrentRecipientId(senderChat.senderId);
                        setShowMessageModal(true);
                      }}
                      className="bg-gray-800 text-white px-2 py-2 rounded-xl mt-2"
                    >
                      Send Message
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </main>
      </div>

      {showMessageModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md rounded-3xl">
            <h2 className="text-xl mb-4">Send a message</h2>
            <div className="flex flex-col">
              <textarea
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                rows="2"
                placeholder="Type your message here..."
                required
              ></textarea>
              <button
                onClick={handleSendMessage}
                className="bg-gray-800 text-white p-2 rounded mt-2"
              >
                Send
              </button>
           
            </div>
            <button
              onClick={() => setShowMessageModal(false)}
              className="bg-red-800 text-white p-2 rounded mt-2 w-full"
            >
              Cancel
            </button>
            {notice && <p className="text-green-500 mt-2 text-center">{notice}</p>}
          </div>
        
        </div>
      )}
       <br/><br/>
    </div>
  );
};

export default Notification;
