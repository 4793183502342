import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Background from './Background';
import Header from './Header'; // Import Header
import { AiFillLike, AiFillMessage } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import { Link } from 'react-router-dom';
import {FaStore} from 'react-icons/fa';

function Market() {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentProductOwner, setCurrentProductOwner] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  
  const [file, setFile] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, [searchTerm]); // Fetch products when searchTerm changes

  const fetchProducts = async () => {
    try {
      const res = await axios.get(`https://mybackend-new.onrender.com/products?search=${searchTerm}`);
      setProducts(res.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  

 
  const toggleDescription = (productId) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };






  
  const getTimeDifference = (timestamp) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const differenceInSeconds = Math.floor((now - createdAt) / 1000);

    const days = Math.floor(differenceInSeconds / (3600 * 24));
    const hours = Math.floor((differenceInSeconds % (3600 * 24)) / 3600);
    const min = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
    } else if (min > 0) {
      return `${min} min${min !== 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
  };

 












  const toggleImage = (image) => {
    console.log("Image clicked");
    setSelectedImage(selectedImage === image ? null : image);
  };


  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.owner_username.toLowerCase().includes(searchTerm.toLowerCase())
  );




  return (
    <div>
        <Header /> 
    <div className="relative min-h-screen flex">
    
   
      <div className="flex-1 p-1 flex">

      {loading ? (<div className='text-black mt-20' >...loading the products</div>
          ): (
      
        <div className="flex-1 p-0 rounded-6xl pt-20">
          <div className=" bg-white">
          <div className="flex items-center justify-center mb-4">
             <FaStore className="text-2xl mr-2" />
              <h2 className="text-2xl font-semibold text-center">Market</h2>
               </div>
            <div className="mb-2">
              <input
                type="text"
                placeholder="Search by name, description, or owner"
                value={searchTerm}
                onChange={handleSearchChange}
                className="p-2 border border-gray-300  w-full rounded-md"
              />
            </div>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-1">
              {filteredProducts.map(product => (
                <div key={product.id} className="border p-1 rounded shadow rounded-md">
                  <img src={`${product.picture}`} alt={product.name} onClick={() => toggleImage(`${product.picture}`)}    className="w-full h-44 object-cover mb-2 rounded-md border-solid border-2 border-gray-100" />
                 
                 
                 <div className='pl-1 pr-1' >
                 
                  <h2 className="text-md font-bold">{product.name}</h2>
                  <div className="text-gray-700 text-sm max-h-24 overflow-hidden">
                    {expandedDescriptions[product.id] || product.description.length <= 5
                      ? product.description
                      : `${product.description.substring(0, 6)}...`}
                    {product.description.length > 5 && (

                      <Link to = "/Login">
                      <span className="text-blue-500 cursor-pointer">
                        Read More
                      </span>
                      </Link>
                    )}
                  </div>
                  <p className="text-sm font-semibold">Price: MK{product.price}</p>
                  <p>Status: {product.status}</p>
               
                 

                  <div className="flex items-center mt-2">
                   
                 
                  <Link to = "/login">
                      <button
                        className="text-gray-800 text-xs font-semibold mr p-0 border-none bg-gray-300 rounded-xl cursor-pointer"
                      >
                       <h6 className='p-2' >Shop Now</h6>
                      </button>
                     
                      </Link>
                    
                  </div>
                  <p className="text-xs mt-2 pb-1">Posted {getTimeDifference(product.created_at)}</p>
                </div>
                </div>
              ))}
            </div>
          </div>
        </div>
         )}
      </div>
     
      
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Product"
              className="max-w-full max-h-full rounded-3xl"
              onClick={() => setSelectedImage(null)}
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-0 right-0 p-2 text-white bg-black rounded-full"
            >
              &times;
            </button>
          </div>
        </div>
      )}
      
    </div>
  </div>
  );
}

export default Market;
