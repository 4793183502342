import React from "react";



const backgrounds = [
    {
      id: 1,
      imageUrl: 'https://images.pexels.com/photos/3965545/pexels-photo-3965545.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' },
    {
      id: 2,
      imageUrl: 'https://source.unsplash.com/random/800x600?nature-2',
    },
  ];


  function Background() {



    return(

            <div>

      <div className="absolute inset-0 z-0">
        {backgrounds.map(Background => (
          <div
            key={Background.id}
            className="absolute inset-0  bg-center  opacity-40 bg-cover"
            style={{ backgroundImage: `url(${Background.imageUrl})` }}
          ></div>
        ))}
      </div>


            </div>

    )
  }

  export default Background;