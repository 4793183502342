import React from 'react';
import InsideHeader from './InsideHeader';

import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FaHouseUser, FaSearch } from 'react-icons/fa';  // Importing Font Awesome icons
import AnotherHeader from './AnotherHeader';
import BackgroundHouse from './BackgroundHouse';

function Accomodation() {

    const [isOpen, setIsOpen] = useState(false);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
      };
      
  return (
    <div>
         <AnotherHeader/>
         <InsideHeader isOpen={isOpen} toggleSidebar={toggleSidebar} />
         <div className="relative min-h-screen">
        <BackgroundHouse/>
    <div className="flex flex-col absolute inset-0 bg-opacity-70 items-center justify-center h-screen bg-gray-100">
      <h1 className="text-2xl font-bold mb-6">Accommodation Rental Service</h1>
      <div className="flex space-x-4">
        <Link to="/PostHouse">
          <button className="flex items-center px-6 py-3 bg-blue-800 text-white rounded shadow hover:bg-blue-700">
            <FaHouseUser className="mr-2" /> Post a Hostel
          </button>
        </Link>
        <Link to="/RentHouse">
          <button className="flex items-center px-6 py-3 bg-gray-600 text-white rounded shadow hover:bg-green-700">
            <FaSearch className="mr-2" /> Find a Hostel
          </button>
        </Link>
      </div>
    </div>
    </div>
    </div>
  );
}

export default Accomodation;
